import React, {useEffect, useState} from 'react';
import './BlogDetailPageView.scss';
import publicService from "../../../service/PublicService";
import {useParams} from "react-router-dom";
import ScrollToTopButton from "../../elements/ScrollToTopButton/ScrollToTopButton";
import {Spin} from "antd";

interface Blog {
    id: number;
    title: string;
    imageUrl: string;
    content: string;
}

const BlogDetailPageView = () => {
    const {id} = useParams<{ id: string }>();
    const [blogs, setBlogs] = useState<Blog>();
    const [loading, setLoading] = useState<boolean>(false);

    const fetchBlog = async (id: number) => {
        setLoading(true);
        try {
            const resp = await publicService.findBlogById(id);
            setBlogs(resp.data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (id) {
            fetchBlog(Number(id));
        }

        window.scroll(0, 0);
    }, [id]);

    useEffect(() => {
        document.title = "Gocard | Chi tiết Blog"
    }, []);

    return (
        <div className="blogs">
            <div className="container-blog-content">
                {loading ? (
                    <div className="spinner-container">
                        <Spin size="default"/>
                    </div>
                ) : blogs ? (
                    <div className="blog-content">
                        <div
                            className="blog-content-items"
                            dangerouslySetInnerHTML={{__html: blogs.content}}
                        />
                    </div>
                ) : (
                    <div>No blog found</div>
                )}
            </div>
            <ScrollToTopButton/>
        </div>
    );
}

export default React.memo(BlogDetailPageView);