import React from 'react';
import "./AButton.scss"

interface AButtonProps {
    name: string;
    onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    href?: string;
    className?: string;
    target?: string;
}

const AButton: React.FC<AButtonProps> = ({
                                             name,
                                             onClick,
                                             className,
                                             href,
                                             target
                                         }) => {
    return (
        <>
            <a target={target} href={href} className={`dg-card-v2-btn-dk01 ${className}`}
               onClick={onClick}>
                <span>{name}</span>
            </a>
        </>
    )
};

export default React.memo(AButton);
