import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import './CardDetailPageView.scss';
import publicService from "../../../service/PublicService";
import {Spin} from "antd";
import ScrollToTopButton from "../../elements/ScrollToTopButton/ScrollToTopButton";

interface LocationState {
    cardItem: any
}

declare global {
    interface Window {
        BM: any;
    }
}

const CardDetailPageView = () => {
    const history = useHistory();
    const location = useLocation<LocationState>();
    const {cardItem} = location.state || {};
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        window.scroll(0, 0);

        // Load BizMail SDK
        const script = document.createElement('script');
        script.src = "https://apicampaign.bizfly.vn/js/sdk.js";
        script.async = true;
        script.defer = true;
        script.onload = () => {
            if (window.BM) {
                window.BM.init({
                    project_token: '70963d3f-220d-449a-8014-35fe11e8fdb2',
                    app_key: 'ad53d774-dfdc-4b4a-a6c3-c62594b62904',
                    version: 'v3.0'
                });
            } else {
                console.error('BizMail SDK not loaded correctly, window.BM is undefined.');
            }
        };
        script.onerror = () => {
            console.error('Failed to load BizMail SDK script.');
        };
        document.body.appendChild(script);
    }, []);

    // Nếu features là một chuỗi JSON, hãy parse nó thành mảng
    let features: string[] = [];
    if (typeof cardItem.features === 'string') {
        try {
            features = JSON.parse(cardItem.features);
        } catch (e) {
            console.error('Error parsing features:', e);
        }
    } else {
        features = cardItem.features || [];
    }

    const handleCreate = async (product: string) => {
        setLoading(true);
        const info = {
            product: product,
        };
        try {
            const resp = await publicService.applyRegister('dang-ky', 'credit-card', info);
            if (resp.status === 200) {

                const UUID = sessionStorage.getItem('user_id');

                await publicService.saveLeadProduct({
                    name: cardItem.name,
                    creditLimitTo: cardItem.creditLimitTo,
                    creditLimitFrom: cardItem.creditLimitFrom,
                    annualFee: cardItem.annualFee,
                    cashAdvanceFee: cardItem.cashAdvanceFee,
                    monthlyIncome: cardItem.monthlyIncome,
                    requiredAge: cardItem.requiredAge,
                    features: cardItem.features,
                    interestRate: cardItem.interestRate,
                    uuid: UUID
                })

                // Gọi BizMail để gửi email khi mở thẻ thành công
                if (window.BM) {
                    const email = sessionStorage.getItem("userEmail")
                    window.BM.importAutomation({
                        uuid: '75c185d6-3cb5-4a08-8d82-5727e3c9b055',
                        source: 'source-name',
                        subscriber: {
                            name: 'Tên người đăng ký',
                            emails: email,
                            phone: 'số điện thoại'
                        }
                    });
                }
                history.push('/success', {cardItem: cardItem});
            }
        } catch (error) {
            console.log("Err", error);
        } finally {
            setLoading(false);
        }
    };

    function formatNumberCustom(number: number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    return (
        <Spin spinning={loading}>
            <div className="mx-3 my-3 fs-1 container-card-details">
                <p className="card-detail-header">Chi tiết thẻ</p>
                <div className="border border-3 px-4 py-4 card-wrapper"
                     style={{backgroundColor: '#edfe8c', height: 'fit-content', borderColor: ''}}>
                    <p className="fw-bold card-name">{cardItem.name}</p>
                    <div className="d-flex flex-row justify-content-around align-item">
                        <img className={
                            `img-card ${
                                cardItem.name.toLowerCase().includes('tpbank evo') ? 'tp-img' :
                                    cardItem.name.toLowerCase().includes('vpbank z') ? 'vp-img' :
                                        cardItem.name.toLowerCase().includes('super shopee platinum') ? 'vp-img' : ''
                            }`
                        }
                             src={cardItem.imageUrl} alt="card"/>
                        <div className="ps-2 open-card">
                            <div className="w-100 position-relative">
                                <button
                                    className="rounded-pill open-card-btn"
                                    onClick={() => handleCreate(cardItem.name)}
                                >Mở thẻ ngay
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="container card-detail-grid">
                        <div className="row px-0">
                            {/* {cardItem.map(() => ( */}
                            <div
                                className="col-12 d-grid justify-content-between py-2 pt-2 px-0 card-detail-info">
                                <div className="text-start right-col">Loại thẻ</div>
                                <div className="text-start left-col">
                                    {cardItem.types}
                                </div>
                            </div>
                            <div
                                className="col-12 d-grid justify-content-between py-2 pt-2 px-0 card-detail-info">
                                <div className="text-start right-col">Phí thường niên</div>
                                <div className="text-start left-col">
                                    {formatNumberCustom(cardItem.annualFee)}
                                </div>
                            </div>
                            <div
                                className="ccol-12 d-grid justify-content-between py-2 pt-2 px-0 card-detail-info">
                                <div className="text-start right-col">Lãi suất năm</div>
                                <div className="text-start left-col">
                                    {cardItem.interestRate}
                                </div>
                            </div>
                            <div
                                className="col-12 d-flex justify-content-between py-2 pt-4 px-0 card-detail-info">
                                <div className="text-start right-col">Tính năng nổi bật</div>
                            </div>
                            {/* ))} */}
                        </div>
                    </div>
                    <div className="container-details-card">
                        <div>
                            {features.map((feature: string, index: number) => (
                                <div
                                    key={index}
                                    className={`text-start d-grid tag ${index === 0 ? "first-feature" : ""
                                    }`}
                                >
                                    <div
                                        className="fw-bold"
                                        dangerouslySetInnerHTML={{__html: feature}}
                                    ></div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <ScrollToTopButton/>
        </Spin>
    );
};

export default React.memo(CardDetailPageView);