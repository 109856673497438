import React, { useEffect } from 'react';
import './LicensePageView1.scss';
import ScrollToTopButton from "../../elements/ScrollToTopButton/ScrollToTopButton";

const LicensePageView1 = () => {
    useEffect(() => {
        document.title = "Gocard | Điều khoản sử dụng";
    }, []);

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-left" style={{padding: '0 20px'}}>
                        <p className="page-title">
                            {/*<span className="back-page" onClick={() => window.history.go(-1)}>
                                <i className="fa fa-angle-left" aria-hidden="true" /></span>*/}
                            <span
                                className="font-size-26 svn-bold"> ĐIỀU KHOẢN VÀ ĐIỀU KIỆN VỀ BẢO VỆ DỮ LIỆU CÁ NHÂN </span>
                        </p>
                        <div className="content-terms font-size-14">
                            <p className="lh-150">
                                <p>Điều khoản và Điều kiện về bảo vệ dữ liệu cá nhân (<b>“Điều khoản”</b>) là thỏa
                                    thuận và điều chỉnh việc Người Dùng - Khách hàng sử dụng dịch vụ của Tổ chức tín
                                    dụng (<b>“Người Dùng”</b>) là cá nhân truy cập website: <a
                                        href="https://www.gocard.vn/">https://www.gocard.vn/</a> (<b>“Gocard”</b>),
                                    đăng ký sử dụng Sản phẩm hợp tác do Tổ chức tín dụng và Công ty CP Công nghệ và
                                    Truyền thông Dagoras (<b>“Dagoras”</b>) phối hợp để triển khai các chương trình
                                    tìm kiếm khách hàng, truyền thông, quảng cáo, cung cấp Sản phẩm hợp tác cho
                                    khách hàng thông qua Gocard.</p>

                                <p>Người dùng với vai trò là Chủ thể dữ liệu xác nhận đã được thông báo về hoạt động
                                    xử lý dữ liệu cá nhân của Dagoras và/hoặc Tổ chức tín dụng theo Điều khoản và
                                    đồng ý cho phép Tổ chức tín dụng và Dagoras, có thể bao gồm cơ quan nhà nước,
                                    các tổ chức cung cấp hệ thống công nghệ thông tin liên quan khác, được phép thu
                                    thập, xử lý các dữ liệu cá nhân do Người dùng cung cấp trên/thông qua Gocard để
                                    phục vụ cho mục đích đăng ký vay, đăng ký mở thẻ tín dụng tại các Tổ chức tín
                                    dụng.</p>

                                <p>Bằng việc truy cập Gocard, đăng ký sử dụng Sản phẩm hợp tác Người Dùng đồng ý
                                    rằng đã đọc kỹ, hiểu rõ và chấp thuận với các điều khoản dưới đây:</p>
                            </p>

                            <h2>ĐIỀU 1: GIẢI THÍCH TỪ NGỮ</h2>

                            <p>
                                <b>1.1. Sản phẩm hợp tác:</b> Là các sản phẩm, dịch vụ của Tổ chức tín dụng cung cấp
                                cho Khách hàng (bao gồm nhưng không giới hạn sản phẩm vay tiêu dùng, thẻ tín dụng,
                                …) hợp tác với Dagoras triển khai các chương trình tìm kiếm, gia tăng khách hàng
                                tiềm năng, triển khai truyền thông, quảng cáo.
                            </p>
                            <p>
                                <b>1.2. Người dùng:</b> là các cá nhân truy cập, đăng ký sử dụng Sản phẩm hợp tác.
                            </p>
                            <p>
                                <b>1.3. Khách hàng:</b> là Người dùng đáp ứng các điều kiện của Tổ chức tín dụng và
                                được các Tổ chức tín dụng cung cấp Sản phẩm hợp tác.
                            </p>
                            <p>
                                <b>1.4. Chủ thể dữ liệu (Bên cung cấp dữ liệu):</b> là cá nhân/Người dùng cung cấp
                                dữ liệu cá nhân cho Bên nhận dữ liệu và cũng là cá nhân được dữ liệu cá nhân phản
                                ánh.
                            </p>
                            <p>
                                <b>1.5. Tổ chức tín dụng:</b> là tổ chức kinh tế có tư cách pháp nhân thực hiện một,
                                một số hoặc tất cả hoạt động ngân hàng; bao gồm nhưng không giới hạn: CÔNG TY TÀI
                                CHÍNH TNHH MTV TNEX, NGÂN HÀNG QUỐC TẾ VIỆT NAM, NGÂN HÀNG TMCP VIỆT NAM THỊNH
                                VƯỢNG
                            </p>
                            <p>
                                <b>1.6. Bên nhận dữ liệu:</b> bao gồm nhưng không giới hạn: Dagoras, Tổ chức tín
                                dụng.
                            </p>
                            <p>
                                <b>1.7. Dữ liệu cá nhân:</b> Dữ liệu cá nhân là thông tin dưới dạng ký hiệu, chữ
                                viết, chữ số, hình ảnh, âm thanh hoặc dạng tương tự trên môi trường điện tử gắn liền
                                với một con người cụ thể hoặc giúp xác định một con người cụ thể. Dữ liệu cá nhân
                                bao gồm dữ liệu cá nhân cơ bản và dữ liệu cá nhân nhạy cảm.
                            </p>
                            <p>
                                <b>1.8. Dữ liệu cá nhân cơ bản bao gồm:<br/></b> a) Họ, chữ đệm và tên khai sinh,
                                tên gọi khác (nếu có);<br/> b) Ngày, tháng, năm sinh; ngày, tháng, năm chết hoặc mất
                                tích;<br/> c) Giới tính;<br/> d) Nơi sinh, nơi đăng ký khai sinh, nơi thường trú,
                                nơi tạm trú, nơi ở hiện tại, quê quán, địa chỉ liên hệ;<br/> đ) Quốc tịch;<br/> e)
                                Hình ảnh của cá nhân;<br/> g) Số điện thoại, số chứng minh nhân dân, số định danh cá
                                nhân, số hộ chiếu, số giấy phép lái xe, số biển số xe, số mã số thuế cá nhân, số bảo
                                hiểm xã hội, số thẻ bảo hiểm y tế;<br/> h) Tình trạng hôn nhân;<br/> i) Thông tin về
                                mối quan hệ gia đình (cha mẹ, con cái);<br/> k) Thông tin về tài khoản số của cá
                                nhân; dữ liệu cá nhân phản ánh hoạt động, lịch sử hoạt động trên không gian
                                mạng;<br/> l) Các thông tin khác gắn liền với một con người cụ thể hoặc giúp xác
                                định một con người cụ thể không thuộc Dữ liệu cá nhân nhạy cảm.
                            </p>
                            <p>
                                <b>1.9. Dữ liệu cá nhân nhạy cảm</b> là dữ liệu cá nhân gắn liền với quyền riêng tư
                                của cá nhân mà khi bị xâm phạm sẽ gây ảnh hưởng trực tiếp tới quyền và lợi ích hợp
                                pháp của cá nhân gồm:<br/> a) Quan điểm ​​chính trị, quan điểm tôn giáo;<br/> b)
                                Tình trạng sức khỏe và đời tư được ghi trong hồ sơ bệnh án, không bao gồm thông tin
                                về nhóm máu;<br/> c) Thông tin liên quan đến nguồn gốc chủng tộc, nguồn gốc dân
                                tộc;<br/> d)
                                Thông tin về đặc điểm di truyền được thừa hưởng hoặc có được của cá nhân;<br/> đ)
                                Thông tin về thuộc tính vật lý, đặc điểm sinh học riêng của cá nhân;<br/> e) Thông
                                tin về đời sống tình dục, xu hướng tình dục của cá nhân;<br/> g) Dữ liệu về tội
                                phạm, hành vi phạm tội được thu thập, lưu trữ bởi các cơ quan thực thi pháp
                                luật;<br/> h) Thông tin khách hàng của tổ chức tín dụng, chi nhánh ngân hàng nước
                                ngoài, tổ chức cung ứng dịch vụ trung gian thanh toán, các tổ chức được phép khác,
                                gồm: thông tin định danh khách hàng theo quy định của pháp luật, thông tin về tài
                                khoản, thông tin về tiền gửi, thông tin về tài sản gửi, thông tin về giao dịch,
                                thông tin về tổ chức, cá nhân là bên bảo đảm tại tổ chức tín dụng, chi nhánh ngân
                                hàng, tổ chức cung ứng dịch vụ trung gian thanh toán;<br/> i) Dữ liệu về vị trí của
                                cá nhân được xác định qua dịch vụ định vị;<br/> k) Dữ liệu cá nhân khác được pháp
                                luật quy định là đặc thù và cần có biện pháp bảo mật cần thiết.
                            </p>
                            <p>
                                <b>1.10. Xử lý dữ liệu cá nhân</b> là một hoặc nhiều hoạt động tác động tới dữ liệu
                                cá nhân, như: thu thập, ghi, phân tích, xác nhận, lưu trữ, chỉnh sửa, công khai, kết
                                hợp, truy cập, truy xuất, thu hồi, mã hóa, giải mã, sao chép, chia sẻ, truyền đưa,
                                cung cấp, chuyển giao, xóa, hủy dữ liệu cá nhân hoặc các hành động khác có liên
                                quan.
                            </p>
                            <p>
                                <b>1.11. Bên thứ ba</b> là các cá nhân, tổ chức ngoài Chủ thể dữ liệu, Bên cung cấp
                                dữ liệu và Bên nhận dữ liệu được phép Xử lý dữ liệu cá nhân theo quy định pháp luật,
                                bao gồm nhưng không giới hạn<br/> (i) công ty mẹ, công ty con, công ty liên kết, chi
                                nhánh, văn phòng đại diện, đối tác, đại lý, nhà thầu hoặc đại diện của Dagoras, các
                                công ty thuộc tập đoàn, công ty con thuộc tập đoàn;<br/> (ii) các nhà cung cấp dịch
                                vụ hoặc thực hiện các dịch vụ thay mặt, hợp tác với Dagoras (bao gồm nhưng không
                                giới hạn bởi dịch vụ CNTT, hậu cần, viễn thông, tư vấn, phân phối, truyền thông và
                                tiếp thị);<br/> (iii) các đối tác của Dagoras nếu việc chia sẻ đó là cần thiết để
                                Dagoras, Đối tác của Dagoras cung cấp dịch vụ cho Tổ chức tín dụng, cung cấp Sản
                                phẩm hợp tác cho Người dùng hoặc thực hiện các nghĩa vụ hoặc giao dịch theo hợp
                                đồng của Dagoras, Đối tác của Dagoras (như đối tác cung cấp các sản phẩm thẻ tín
                                dụng, chấm điểm tín dụng cá nhân, xác minh dữ liệu, …);<br/> (iv) kiểm toán viên,
                                các nhà tư vấn về pháp lý/kế toán/thuế/bảo hiểm, … của Dagoras, các công ty thuộc
                                tập đoàn, công ty con thuộc tập đoàn;<br/> (v) nhà cung cấp hệ thống máy tính, máy
                                chủ, lưu trữ, lắp đặt và bảo trì;<br/> (vi) bất kỳ cá nhân, tổ chức tham gia vào quá
                                trình triển khai các chương trình tìm kiếm, gia tăng khách hàng tiềm năng, triển
                                khai truyền thông, quảng cáo Sản phẩm hợp tác;<br/> (vii) các bên thứ ba khác hợp
                                pháp hoặc được yêu cầu bởi luật, quy định, lệnh của tòa án, hành động hoặc yêu cầu
                                khác của chính phủ; hoặc theo yêu cầu từ Dagoras nhằm phục vụ cho hoạt động Xử lý dữ
                                liệu cá nhân.
                            </p>

                            <h2>ĐIỀU 2: NGUYÊN TẮC ÁP DỤNG </h2>

                            <p>
                                <b>2.1.</b> Người dùng có trách nhiệm đọc kỹ Điều khoản trước khi chọn Đồng ý hoặc
                                không Đồng ý. Việc Người dùng chọn Đồng ý được hiểu là Người dùng đã đọc, hiểu đầy
                                đủ, chi tiết và hoàn toàn Đồng ý với nội dung của Điều khoản. Trường hợp, Người dùng
                                không chọn Đồng ý hoặc chọn Không Đồng ý/Chỉ đồng ý một phần, Dagoras sẽ (i) từ chối
                                cho Người dùng truy cập Gocard, đăng ký sử dụng Sản phẩm hợp tác trên/thông qua
                                Gocard; (ii) có quyền quyết định đơn phương chấm dứt các công việc/giao dịch đang
                                thực hiện với Người dùng/Bên cung cấp dữ liệu và yêu cầu bồi thường thiệt hại (nếu
                                có) tùy thuộc vào việc Dagoras đánh giá rủi ro tiềm ẩn nguy cơ vi phạm pháp luật
                                hoặc gây thiệt hại cho Dagoras.
                            </p>
                            <p>
                                <b>2.2.</b> Việc thông báo về việc Xử lý dữ liệu cá nhân được Dagoras thực hiện một
                                lần theo các nội dung tại Điều khoản trước khi tiến hành đối với hoạt động Xử lý dữ
                                liệu cá nhân. Dagoras sẽ không gửi thông báo cho Người dùng mỗi khi tiến hành một
                                hoặc nhiều hoạt động Xử lý dữ liệu cá nhân theo Điều khoản.
                            </p>
                            <p>
                                <b>2.3.</b> Dữ liệu cá nhân của Người dùng sẽ được xử lý mà không cần sự đồng ý của
                                Người dùng khi Dagoras xử lý dữ liệu để thực hiện nghĩa vụ theo Hợp đồng/Thỏa
                                thuận/Văn bản và tất cả các Văn bản đính kèm của Hợp đồng/Thỏa thuận/Văn bản của
                                Người dùng với Dagoras hoặc các trường hợp khác theo quy định của pháp luật.
                            </p>
                            <p>
                                <b>2.4.</b> Việc Người dùng đồng ý với Điều khoản này (i) không đồng nghĩa Người
                                dùng được đảm bảo Tổ chức tín dụng sẽ chấp thuận cung cấp Sản phẩm hợp tác và (ii)
                                không ảnh hưởng đến các thỏa thuận khác được ký kết giữa Người dùng và Dagoras/Tổ
                                chức tín dụng.
                            </p>

                            <h2>ĐIỀU 3: DỮ LIỆU CÁ NHÂN TIẾN HÀNH XỬ LÝ</h2>

                            <p>
                                Dữ liệu cá nhân mà Người dùng cung cấp cho Dagoras là các Dữ liệu cá nhân cơ bản
                                theo quy định pháp luật và các thông tin khác do Người dùng nhập, cung cấp, … bao
                                gồm nhưng không giới hạn:
                            </p>
                            <ul className='list-info'>
                                <li> Họ và Tên;</li>
                                <li> Số điện thoại;</li>
                                <li> Email;</li>
                                <li> Ngày, tháng, năm sinh;</li>
                                <li> Giới tính;</li>
                                <li> Số CMND/CCCD/Số định danh cá nhân;</li>
                                <li> Nhu cầu về Sản phẩm hợp tác như số tiền vay, thời hạn vay, …</li>
                            </ul>

                            <h2>ĐIỀU 4: MỤC ĐÍCH XỬ LÝ DỮ LIỆU CÁ NHÂN</h2>

                            <p>
                                Dữ liệu cá nhân được Dagoras thu thập, xử lý và sử dụng cho các mục đích của
                                Dagoras và/hoặc Tổ chức tín dụng bao gồm:
                            </p>
                            <p>
                                <b>4.1. Mục đích kinh doanh:</b> Bao gồm nhưng không giới hạn các nội dung sau đây:
                            </p>
                            <p>
                                <b>a. Cung cấp dịch vụ của Dagoras cho các Tổ chức tín dụng; triển khai các hoạt
                                    động tìm kiếm, gia tăng khách hàng tiềm năng cho các Tổ chức tín dụng </b>
                            </p>
                            <p>
                                <b>b. Giới thiệu, cung cấp Sản phẩm hợp tác</b><br/>
                                - Triển khai truyền thông, quảng cáo, liên lạc, tư vấn, giới thiệu cho Người dùng về
                                Sản phẩm hợp tác;<br/>

                                - Sàng lọc, kiểm tra điều kiện cung cấp sản phẩm, dịch vụ, xác thực thông tin Người
                                dùng hoặc bất kỳ hoạt động nào khác phục vụ thẩm định, xét duyệt nhu cầu của Người
                                dùng theo quy định của Tổ chức tín dụng và pháp luật hiện hành;<br/>

                                -Giao kết và thực hiện (các) hợp đồng, thỏa thuận, cam kết, biên bản hoặc bất kỳ tài
                                liệu nào khác giữa Dagoras và Người dùng theo các phương thức được các bên thống
                                nhất;<br/>

                                -Theo dõi, đôn đốc việc thực hiện các nghĩa vụ của Người dùng đối với Dagoras như
                                được quy định tại hợp đồng, thỏa thuận, cam kết, biên bản, điều khoản sử dụng hoặc
                                bất kỳ tài liệu nào khác giữa các bên.<br/>

                                - Các mục đích khác liên quan đến cung cấp Sản phẩm hợp tác cho Người Dùng.<br/>
                            </p>
                            <p>
                                <b>c. Thực hiện hoạt động xúc tiến thương mại </b><br/>
                                - Gửi đến khách hàng các nội dung chương trình, sự kiện, chiến dịch hoặc khuyến
                                mại, quảng cáo, tiếp thị của Dagoras, Tổ chức tín dụng bao gồm nhưng không giới hạn
                                chương trình trao thưởng, rút thăm may mắn, gửi quà tặng và giải thưởng và bất kỳ
                                hình thức xúc tiến thương mại khác theo quy định của pháp luật hiện hành.<br/>

                                - Giới thiệu, truyền thông, quảng cáo về các sản phẩm, dịch vụ mới, Sản phẩm hợp tác
                                mới.<br/>

                                - Liên lạc, cung cấp các thông báo, khảo sát, thông tin Sản phẩm hợp tác, làm rõ nhu
                                cầu, yêu cầu của Người dùng về Sản phẩm hợp tác, thực hiện các hoạt động truyền
                                thông, quảng cáo, khuyến mại, phân tích, đánh giá, tối ưu các hoạt động truyền
                                thông, quảng cáo, khuyến mại tới Người dùng.<br/>

                                - Thực hiện hoạt động xúc tiến thương mại, truyền thông, quảng cáo với tần suất giới
                                thiệu, truyền thông, quảng cáo, thông báo không hạn chế, không giới hạn không gian,
                                thời gian bằng các hình thức bao gồm nhưng không giới hạn được thể hiện bằng tiếng
                                nói, chữ viết, biểu tượng, màu sắc, ánh sáng, hình ảnh, hành động, âm thanh và các
                                hình thức khác có khả năng truyền đạt nội dung, thông tin.<br/>
                            </p>
                            <p>
                                <b>d. Phát triển sản phẩm, dịch vụ</b><br/>
                                - Thực hiện nghiên cứu, phân tích, thống kê trải nghiệm, cách thức sử dụng, nhu cầu
                                sử dụng của Người dùng, khách hàng đối với Sản phẩm hợp tác, sản phẩm, dịch vụ
                                mới;<br/>

                                - Cải thiện, sửa đổi các sản phẩm, dịch vụ hiện tại;<br/>

                                - Lập kế hoạch, phát triển các sản phẩm, dịch vụ đặc thù phù hợp với sở thích, thói
                                quen của từng nhóm khách hàng, Người dùng;<br/>

                                - Đánh giá, kiểm tra, thử nghiệm các hệ thống hoặc tính năng mới của Dagoras.<br/>
                            </p>
                            <p>
                                <b>e. Thực hiện các hoạt động chăm sóc khách hàng</b><br/>
                                - Giải quyết hoặc điều tra bất kỳ yêu cầu, phản ánh, khiếu nại nào của Người
                                dùng;<br/>

                                - Liên lạc với Người dùng, bao gồm cả việc cung cấp thông tin cập nhật về các thay
                                đổi đối với sản phẩm, dịch vụ của Dagoras, Tổ chức tín dụng bao gồm bất kỳ sự bổ
                                sung, mở rộng, đình chỉ và thay thế đối với các sản phẩm, dịch vụ và các điều khoản
                                và điều kiện đi kèm.
                            </p>

                            <p>
                                <b>4.2. Mục đích quản trị nội bộ</b>
                            </p>
                            <p>
                                Thực hiện các nghĩa vụ của Dagoras khi lấy ý kiến tư vấn hoặc cố vấn chuyên môn,
                                bao gồm nhưng không giới hạn tư vấn pháp lý, kế toán, kiểm toán, quản trị rủi ro,
                                kiểm soát nội bộ, đánh giá tín nhiệm, … kiểm tra, thử nghiệm các hệ thống hoặc tính
                                năng mới của Dagoras.
                            </p>

                            <p><b>4.3. Mục đích khác </b></p>
                            <p>Bên cạnh những mục đích đã nêu, Người dùng đồng ý cho Dagoras Xử lý dữ liệu cá nhân
                                cho bất kỳ mục đích nào khác mà Dagoras cho là phù hợp để thực hiện quyền của
                                Dagoras theo các hợp đồng, thỏa thuận giữa Dagoras và Người dùng, Điều khoản này với
                                điều kiện là việc Xử lý dữ liệu cá nhân phù hợp với quy định của pháp luật hiện
                                hành. </p>

                            <h2>ĐIỀU 5: CÁCH THỨC XỬ LÝ DỮ LIỆU</h2>

                            <p>
                                <b>5.1. Thu thập Dữ liệu </b>
                            </p>
                            <p>
                                <b>- Từ Gocard:</b> Dagoras có thể thu thập Dữ liệu cá nhân khi Người dùng truy cập
                                Gocard, điền dữ liệu tại các ô/trường thu thập thông tin và click chọn hoặc đánh dấu
                                vào ô “Đăng ký”, “Xem kết quả” hoặc chọn các thiết lập kỹ thuật đồng ý hoặc qua một
                                hành động tương tự, hoặc sử dụng bất kỳ tính năng, tài nguyên nào có sẵn trên
                                hoặc thông qua Gocard.
                            </p>
                            <p>
                                <b>- Từ các trao đổi, liên lạc của nhân viên, cộng tác viên của Dagoras và/hoặc Tổ
                                    chức tín dụng với Người dùng:</b> Dagoras có thể thu thập Dữ liệu cá nhân khi
                                Người dùng cung cấp, chia sẻ cho Dagoras thông qua các hình thức gặp mặt trực tiếp,
                                qua thư, điện thoại, tin nhắn, trực tuyến, liên lạc điện tử hoặc bất kỳ phương tiện
                                liên lạc nào khác.
                            </p>
                            <p>
                                <b>- Từ các tương tác với nội dung hoặc quảng cáo của Dagoras/Tổ chức tín dụng/Bên
                                    thứ ba</b> hoặc sử dụng, tiếp cận sản phẩm, dịch vụ của Dagoras, Sản phẩm hợp
                                tác thông qua trang tin điện tử, tin nhắn, thư điện tử, cuộc gọi, ứng dụng hoặc bất
                                kỳ hình thức tương tác nào khác;
                            </p>
                            <p>
                                <b>- Từ các tương tác hoặc các công nghệ thu thập dữ liệu tự động:</b> Dagoras có
                                thể thu thập thông tin bao gồm:<br/>
                                <span>
                                    + Địa chỉ IP, URL giới thiệu, hệ điều hành, trình duyệt tin điện tử và bất kỳ thông tin nào khác được ghi tự động từ kết nối, thao tác, hành trình của Người dùng;
                                </span><br/>
                                <span>
                                    + Traffic chung trên Gocard và hành vi, trạng thái theo UID: Số lượng Người dùng truy cập theo thời gian và theo từng nguồn; thời gian trung bình, thời gian trên mỗi thao tác/hành trình; hành vi tương tác, số lượt tương tác (click, scroll); mức độ, trạng thái, tiến độ đăng ký Sản phẩm hợp tác của Người dùng trên Gocard: để lại thông tin, hoàn thành OTP, hoàn thành điền thông tin đăng ký Sản phẩm hợp tác, đã đăng ký Sản phẩm hợp tác; số lượng UID điền thông tin, tỷ lệ hoàn thành, thời gian hoàn thành trên các bước.
                                </span><br/>
                                <span>
                                    + Cookie, flash cookie, thẻ pixel, tin điện tử beacons, hoặc các công nghệ theo dõi khác.
                                </span><br/>
                                <span>
                                    + Cookie, plug-in hoặc trình kết nối mạng xã hội của bên thứ ba.
                                </span><br/>
                                <span>
                                    + Bất kỳ công nghệ nào có khả năng thu thập hoạt động cá nhân trên các thiết bị hoặc trang tin điện tử.
                                </span>
                            </p>
                            <p>
                                <b>- Từ các Bên thứ ba:</b> Dagoras có thể tiếp nhận Dữ liệu cá nhân của Người dùng
                                từ các Bên thứ ba có quyền cung cấp, chia sẻ dữ liệu cá nhân của Người dùng theo quy
                                định pháp luật và thỏa thuận/hợp tác với Dagoras/Tổ chức tín dụng. Việc thu thập Dữ
                                liệu cá nhân qua Bên thứ ba được thực hiện qua phương thức truyền API, truyền qua
                                file hoặc bất kỳ phương thức nào khác đảm bảo tính an toàn và bảo mật thông tin
                                phù hợp với quy định pháp luật.
                            </p>
                            <p>
                                <b>- Các phương tiện khác:</b> Dagoras có thể thu thập hoặc lấy Dữ liệu cá nhân khi
                                Người dùng tương tác với Dagoras thông qua bất kỳ phương tiện nào khác.
                            </p>

                            <p>
                                <b>5.2. Phân tích Dữ liệu cá nhân </b>
                            </p>
                            <p>
                                <b>a.</b> Đối với Dữ liệu cá nhân mà Dagoras thu thập được qua các cách thức quy
                                định tại Điều khoản này, Dagoras sẽ thực hiện phân tích thông qua so sánh, đối
                                chiếu, thẩm định, tổng hợp, … phục vụ cho các mục đích được quy định tại Điều 4
                                Điều khoản này. Hoạt động phân tích Dữ liệu cá nhân sẽ được Dagoras thực hiện trên
                                hệ thống nội bộ của Dagoras với các biện pháp kỹ thuật bảo mật dữ liệu cần thiết và
                                phù hợp với quy định của pháp luật.
                            </p>
                            <p>
                                <b>b.</b> Trong trường hợp việc phân tích được thực hiện qua hệ thống của Bên thứ
                                ba, các đối tác của Dagoras, Dagoras sẽ yêu cầu các đối tác này thực hiện đầy đủ các
                                biện pháp bảo vệ dữ liệu theo quy định của pháp luật.
                            </p>

                            <p>
                                <b>5.3. Chia sẻ Dữ liệu cá nhân </b>
                            </p>
                            <p>
                                Dagoras có thể chia sẻ Dữ liệu cá nhân của Người dùng cho các Tổ chức tín dụng,
                                Bên thứ ba quy định tại Điều 1 Điều khoản này để thực hiện các Mục đích xử lý dữ
                                liệu cá nhân.
                            </p>

                            <p>
                                <b>5.4. Lưu trữ Dữ liệu cá nhân </b>
                            </p>
                            <p>
                                Dagoras sẽ sử dụng ứng dụng đám mây, hệ thống máy chủ để lưu trữ trong quá trình
                                xử lý dữ liệu và thực hiện các biện pháp hợp lý để bảo vệ Dữ liệu cá nhân. Việc lưu
                                trữ có thể được một nhà cung cấp dịch vụ khác thay mặt Dagoras thực hiện. Khi nhà
                                cung cấp dịch vụ thứ ba được sử dụng, Dagoras đảm bảo có hợp đồng hợp pháp và yêu
                                cầu nhà cung cấp bảo mật Dữ liệu cá nhân, đồng thời thực hiện đầy đủ các nghĩa vụ
                                theo quy định của pháp luật.
                            </p>

                            <p>
                                <b>5.5. Các hoạt động xử lý khác </b>
                            </p>
                            <p>
                                Các hoạt động xử lý khác đối với Dữ liệu cá nhân phục vụ mục đích quy định tại
                                Điều Khoản này và phù hợp với quy định pháp luật.
                            </p>

                            <h2>ĐIỀU 6: QUYỀN VÀ NGHĨA VỤ CỦA NGƯỜI DÙNG </h2>

                            <p>
                                Người dùng sẽ có đầy đủ quyền và nghĩa vụ của Người dùng theo quy định của pháp
                                luật về bảo vệ Dữ liệu cá nhân, bao gồm:
                            </p>
                            <p>
                                <b>6.1.</b> Được biết về hoạt động Xử lý dữ liệu như đã được đề cập tại Điều khoản
                                này.
                            </p>
                            <p>
                                <b>6.2.</b> Được quyền đồng ý hoặc không đồng ý cho phép Xử lý dữ liệu.
                            </p>
                            <p>
                                <b>6.3.</b> Được yêu cầu Dagoras cung cấp Dữ liệu, được hỗ trợ truy cập để xem Dữ
                                liệu hoặc đề nghị Dagoras cập nhật, chỉnh sửa khi nhận thấy Dữ liệu mà Dagoras đang
                                nắm giữ là không chính xác, không đầy đủ. Người dùng hiểu rằng, Dagoras có thể từ
                                chối yêu cầu truy cập vào Dữ liệu trong một số trường hợp nhất định như không xác
                                định được danh tính của Người dùng, dữ liệu được yêu cầu có tính chất bảo mật hoặc
                                Người dùng lặp lại yêu cầu đối với cùng một dữ liệu.
                            </p>
                            <p>
                                <b>6.4.</b> Người dùng có thể rút lại sự đồng ý của mình đối với bất kỳ hoạt động Xử
                                lý Dữ liệu nào được nêu tại Điều khoản dưới hình thức văn giấy hoặc văn bản điện tử
                                có thể kiểm chứng được. Tuy nhiên, Người dùng hiểu và đồng ý rằng, việc rút lại sự
                                đồng ý sẽ loại trừ đối với mục đích Xử lý Dữ liệu của Dagoras nhằm bảo vệ quyền và
                                lợi ích hợp pháp của Dagoras, thực hiện các trách nhiệm báo cáo, quản trị, lưu trữ
                                theo quy định của pháp luật và/hoặc theo yêu cầu của cơ quan nhà nước có thẩm quyền.
                            </p>
                            <p>
                                <b>6.5.</b> Được khiếu nại, tố cáo, khởi kiện, yêu cầu bồi thường thiệt hại; quyền
                                tự bảo vệ theo quy định của pháp luật, hoặc yêu cầu cơ quan, tổ chức có thẩm quyền
                                thực hiện các phương thức bảo vệ quyền dân sự theo quy định của pháp luật.
                            </p>
                            <p>
                                <b>6.6.</b> Khách hàng có các nghĩa vụ:<br/>

                                - Tự bảo vệ Dữ liệu;<br/>

                                - Yêu cầu các tổ chức, cá nhân khác có liên quan bảo vệ Dữ liệu;<br/>

                                - Tôn trọng, bảo vệ dữ liệu cá nhân của người khác. Nếu một cá nhân cung cấp cho
                                Dagoras Dữ liệu cá nhân của một bên thứ ba (bao gồm nhưng không giới hạn: thông tin
                                của người phụ thuộc, người có liên quan theo quy định của pháp luật, vợ/chồng, con
                                cái và/hoặc cha mẹ và/hoặc người giám hộ, bạn bè, người tham chiếu, bên thụ hưởng,
                                người được ủy quyền, đối tác, người liên hệ trong các trường hợp khẩn cấp), cá nhân
                                cam đoan, bảo đảm và chịu trách nhiệm rằng đã có được sự đồng ý hợp pháp của bên
                                thứ ba cho phép Dagoras Xử lý dữ liệu cá nhân theo mục đích và điều khoản, điều
                                kiện quy định tại Điều khoản;<br/>

                                - Cung cấp đầy đủ, chính xác Dữ liệu khi đồng ý cho phép Xử lý dữ liệu.
                            </p>
                            <p>
                                <b>6.7.</b> Các quyền và nghĩa vụ khác theo quy định của pháp luật.
                            </p>

                            <h2>ĐIỀU 7: XÓA, HỦY, CHẤM DỨT XỬ LÝ DỮ LIỆU</h2>
                            <p>
                                <b>7.1.</b> Một yêu cầu rút lại sự đồng ý, yêu cầu hạn chế, phản đối Xử lý dữ liệu
                                cá nhân và/hoặc xóa Dữ liệu cá nhân được coi là hợp lệ khi thực hiện theo trình tự,
                                thủ tục và đáp ứng đúng và đầy đủ tất cả các điều kiện được quy định tại Điều khoản
                                hoặc được Dagoras/Tổ chức tín dụng thông báo, chỉ dẫn theo các phương thức khác tùy
                                từng thời kỳ, đồng thời đáp ứng các điều kiện dưới đây:
                            </p>
                            <p>
                                a. Được thực hiện bởi chính Chủ thể dữ liệu mà không phải thông qua đại diện hay ủy
                                quyền dưới bất kỳ hình thức nào, trừ các trường hợp pháp luật quy định phải thực
                                hiện thông qua đại diện hay ủy quyền;<br/>

                                b. Yêu cầu rút lại sự đồng ý, yêu cầu hạn chế, phản đối Xử lý dữ liệu cá nhân,
                                và/hoặc xóa Dữ liệu cá nhân không áp dụng, trừ trường hợp pháp luật có quy định
                                khác, khi xảy ra một hoặc đồng thời các trường hợp:<br/>

                                (i). Pháp luật quy định không cho phép rút lại sự đồng ý, yêu cầu hạn chế, phản đối
                                Xử lý dữ liệu cá nhân và/hoặc xóa Dữ liệu cá nhân;<br/>

                                (ii). Việc Xử lý dữ liệu cá nhân được thực hiện bởi cơ quan nhà nước có thẩm quyền
                                với mục đích phục vụ hoạt động của cơ quan nhà nước theo quy định pháp luật;<br/>

                                (iii). Dữ liệu cá nhân đã được công khai theo quy định pháp luật;<br/>

                                (iv). Việc Xử lý dữ liệu cá nhân được thực hiện nhằm phục vụ yêu cầu pháp lý, nghiên
                                cứu khoa học, thống kê theo quy định pháp luật;<br/>

                                (v). Trong trường hợp tình trạng khẩn cấp về quốc phòng, an ninh quốc gia, trật tự
                                an toàn xã hội, thảm họa lớn, dịch bệnh nguy hiểm, khi có nguy cơ đe họa an ninh,
                                quốc phòng nhưng chưa đến mức độ ban bố tình trạng khẩn cấp, phòng, chống bạo loạn,
                                khủng bố, phòng, chống tội phạm và vi phạm pháp luật;<br/>

                                (vi). Ứng phó với tình huống khẩn cấp đe dọa đến tính mạng, sức khỏe hoặc sự an toàn
                                của Chủ thể dữ liệu hoặc cá nhân khác;<br/>

                                (vii). Các trường hợp mà Dagoras/Tổ chức tín dụng không thể thực hiện được các yêu
                                cầu của Chủ thể dữ liệu cho dù vì bất kỳ lý do gì, bao gồm nhưng không giới hạn
                                trường hợp hệ thống công nghệ, hạ tầng của Dagoras/Tổ chức tín dụng không cho phép
                                hoặc nếu, theo đánh giá hợp lý của Dagoras/Tổ chức tín dụng, việc thực hiện yêu cầu
                                của Chủ thể dữ liệu sẽ hoặc có thể sẽ gây nguy hại và/hoặc có thể ảnh hưởng tới khả
                                năng hoạt động liên tục, liền mạch, không bị ngắt quãng của Dagoras/Tổ chức tín
                                dụng.<br/>

                                c. Chủ thể dữ liệu đã hiểu rõ và đồng ý cam kết sẽ chịu trách nhiệm về mọi hậu quả,
                                thiệt hại có thể xảy ra do hành động rút lại sự đồng ý, thực hiện yêu cầu hạn chế,
                                phản đối Xử lý dữ liệu cá nhân và/hoặc xóa Dữ liệu cá nhân gây ra (nếu có).<br/>

                                d. Hoàn tất thanh toán đầy đủ và đúng hạn các loại phí, chi phí liên quan đến việc
                                thực hiện yêu cầu rút lại sự đồng ý, yêu cầu hạn chế, phản đối Xử lý dữ liệu cá nhân
                                và/hoặc xóa Dữ liệu cá nhân.<br/>

                                e. Yêu cầu rút lại sự đồng ý, yêu cầu hạn chế, phản đối Xử lý dữ liệu cá nhân,
                                và/hoặc xóa Dữ liệu cá nhân phải được lập thành văn bản gửi đến Dagoras theo địa chỉ
                                liên hệ của Dagoras quy định tại Điều 13 Điều khoản.
                            </p>

                            <p>
                                <b>7.2.</b> Dagoras/Tổ chức tín dụng có quyền từ chối thực hiện toàn bộ yêu cầu của
                                Chủ thể dữ liệu nếu yêu cầu không đáp ứng điều kiện hợp lệ theo quy định tại Điều
                                này.
                            </p>
                            <p>
                                <b>7.3.</b> Việc rút lại sự đồng ý, yêu cầu hạn chế, phản đối Xử lý dữ liệu cá nhân
                                và/hoặc xóa Dữ liệu cá nhân không ảnh hưởng đến tính hợp pháp của việc xử lý dữ liệu
                                đã được đồng ý trước khi rút lại sự đồng ý, yêu cầu hạn chế, phản đối Xử lý dữ liệu
                                cá nhân và/hoặc xóa Dữ liệu cá nhân.
                            </p>

                            <h2>ĐIỀU 8: TUÂN THỦ THỜI GIAN XỬ LÝ DỮ LIỆU CÁ NHÂN </h2>

                            <p>
                                <b>8.1. Thời gian bắt đầu </b><br/>
                                Dagoras có thể bắt đầu Xử lý dữ liệu cá nhân của Người dùng tại bất kỳ thời điểm
                                nào sau khi được Người dùng cung cấp, nhập Dữ liệu cá nhân và đồng ý theo các cách
                                thức quy định tại Điều khoản.
                            </p>
                            <p>
                                <b>8.2. Thời gian kết thúc </b><br/>
                                Dagoras sẽ chấm dứt Xử lý dữ liệu cá nhân khi Người dùng yêu cầu Dagoras xóa Dữ
                                liệu cá nhân phù hợp với quy định của Điều khoản và quy định của pháp luật hoặc khi
                                xảy ra một hoặc các sự kiện dưới đây (tùy sự kiện nào diễn ra sau cùng):<br/>
                                <span>
                                    - Người dùng đã hoàn thành mọi nghĩa vụ với Dagoras theo hợp đồng/thỏa thuận/cam kết/… ký kết/xác lập/thống nhất với Dagoras.<br/>

                                    - Dagoras đã hoàn thành các mục đích Xử lý dữ liệu cá nhân hoặc kết thúc các hoạt động Xử lý dữ liệu cá nhân của Người dùng theo Điều khoản.<br/>

                                    - Dagoras hoàn thành nghĩa vụ lưu trữ hồ sơ liên quan đến Dữ liệu cá nhân theo quy định của pháp luật.<br/>

                                    - Người dùng rút lại sự đồng ý, yêu cầu chấm dứt việc Xử lý dữ liệu cá nhân, yêu cầu xóa Dữ liệu cá nhân và/hoặc thực hiện các quyền có liên quan khác đối với bất kỳ hoặc tất cả các Dữ liệu cá nhân của của mình. Tuỳ thuộc vào bản chất yêu cầu của Người dùng, Dagoras/Tổ chức tín dụng sẽ xem xét và quyết định về việc không tiếp tục cung cấp các Sản phẩm hợp tác, cung cấp các sản phẩm, dịch vụ cho Người dùng. Các yêu cầu từ Người dùng có thể được xem là sự đơn phương chấm dứt từ phía Người dùng, và hoàn toàn có thể dẫn đến sự vi phạm nghĩa vụ hoặc các cam kết theo thỏa thuận giữa Người dùng và Dagoras/Tổ chức tín dụng; đồng thời Dagoras/Tổ chức tín dụng có quyền bảo lưu các quyền và biện pháp khắc phục hợp pháp trong những trường hợp đó. Ngoài ra, Dagoras/Tổ chức tín dụng sẽ không phải chịu trách nhiệm đối với Người dùng cho bất kỳ tổn thất, thiệt hại nào phát sinh từ hoặc liên quan đến việc Người dùng thực hiện quyền đối với Dữ liệu cá nhân của mình.
                                </span>
                            </p>

                            <h2>ĐIỀU 9: BẢO VỆ DỮ LIỆU </h2>

                            <p>
                                <b>9.1.</b> Dữ liệu được áp dụng các biện pháp bảo vệ, bảo mật trong quá trình xử
                                lý, bao gồm cả việc bảo vệ trước các hành vi vi phạm quy định về bảo vệ dữ liệu và
                                phòng, chống sự mất mát, phá hủy hoặc thiệt hại do sự cố, sử dụng các biện pháp kỹ
                                thuật.
                            </p>
                            <p>
                                <b>9.2.</b> Nghĩa vụ bảo mật không áp dụng đối với dữ liệu, thông tin được cung cấp
                                công khai mà không vi phạm nghĩa vụ bảo mật, hoặc dữ liệu, thông tin đã nhận được từ
                                Bên thứ ba mà không có nghĩa vụ bảo mật, hoặc dữ liệu, thông tin do Dagoras, Đối tác
                                của Dagoras, Bên thứ ba tự tìm ra, được Bên bất kỳ cung cấp, tiết lộ và Bên bất kỳ
                                không có nghĩa vụ bảo mật.
                            </p>

                            <h2>ĐIỀU 10: HẬU QUẢ, THIỆT HẠI KHÔNG MONG MUỐN CÓ KHẢ NĂNG XẢY RA</h2>

                            <p>
                                Dagoras sẽ áp dụng các phương pháp xử lý dữ liệu thích hợp cũng như các biện pháp
                                bảo mật kỹ thuật và tổ chức thích hợp, để tránh việc truy cập, đọc, sử dụng, thay
                                đổi, cung cấp, phá hủy trái phép hoặc các quá trình xử lý Dữ liệu cá nhân. Tuy
                                nhiên, Người dùng và Dagoras đồng ý rằng Dagoras không thể lường trước tất cả hậu
                                quả, thiệt hại không mong muốn xảy ra đối với Dữ liệu cá nhân do yếu tố tác động từ
                                bên ngoài và các yếu tố chủ quan và khách quan, bao gồm nhưng không giới hạn:<br/>
                                <span>
                                    - Mã độc, tấn công mạng, xâm nhập mạng, tội phạm an ninh mạng, lỗ hổng bảo mật nằm ngoài khả năng kiểm soát gây lộ lọt dữ liệu;<br/>

                                    - Lỗi phần cứng, phần mềm trong quá trình xử lý dữ liệu làm mất dữ liệu của Người dùng;<br/>

                                    - Người dùng tự làm lộ lọt dữ liệu cá nhân do: bất cẩn hoặc bị lừa đảo; truy cập các website/tải các ứng dụng có chứa phần mềm độc hại, ...
                                </span>
                            </p>

                            <h2>ĐIỀU 11: MIỄN TRỪ TRÁCH NHIỆM </h2>

                            <p>
                                Dagoras sẽ được miễn trừ trách nhiệm đối với các hậu quả, thiệt hại xảy ra đối với
                                Dữ liệu cá nhân nếu hậu quả, thiệt hại đó phát sinh hoàn toàn do lỗi của Người dùng
                                hoặc do xảy ra sự kiện nêu tại Điều 10 Điều khoản này hoặc sự kiện bất khả kháng
                                khác theo quy định của pháp luật.
                            </p>

                            <h2>ĐIỀU 12: QUYỀN TỪ CHỐI CUNG CẤP DỊCH VỤ</h2>

                            <p>
                                <b>12.1.</b> Trong mọi trường hợp, Dagoras có quyền đơn phương từ chối cung cấp dịch
                                vụ tạm thời hoặc vĩnh viễn với Người dùng hoặc Người dùng bị nghi vấn có thực hiện
                                một trong các hành vi sau:<br/>
                                <span>
                                    - Cung cấp thông tin, hồ sơ sai lệch, không trung thực, không chính xác, thiếu thông tin, dữ liệu;<br/>

                                    - Cung cấp thông tin, hồ sơ giả mạo của cá nhân khác.
                                </span>
                            </p>
                            <p>
                                <b>12.2.</b> Việc Người dùng đăng ký sử dụng Sản phẩm hợp tác thành công trên Gocard
                                không đồng nghĩa với việc Người dùng đáp ứng các điều kiện, tiêu chuẩn theo quy định
                                của các Tổ chức tín dụng. Các Tổ chức tín dụng có toàn quyền thẩm định, đánh giá,
                                quyết định việc cung cấp Sản phẩm hợp tác cho Người dùng.
                            </p>

                            <h2>ĐIỀU 13: THÔNG BÁO VÀ LIÊN HỆ </h2>

                            <p>
                                <b>13.1.</b> Tất cả các thông báo hoặc giao tiếp từ Dagoras sẽ được gửi/thông báo
                                đến Người dùng thông qua các cách thức sau đây:
                            </p>
                            <p>
                                <b>a.</b> Công bố, niêm yết, đăng tải, hiển thị công khai tại Gocard. Người dùng
                                được coi là đã nhận được thông báo vào ngày thông báo được công bố, niêm yết, đăng
                                tải, hiển thị công khai;
                            </p>
                            <p>
                                <b>b.</b> Gặp mặt trao đổi trực tiếp hoặc trao đổi qua điện thoại. Người dùng được
                                coi là đã nhận được thông báo tại thời điểm trao đổi trên thực tế giữa các bên;
                            </p>
                            <p>
                                <b>c.</b> Bất kỳ phương tiện điện tử nào (bao gồm nhưng không giới hạn: SMS, email,
                                ứng dụng, website); Người dùng được coi là đã nhận được thông báo khi hệ thống
                                phương tiện điện tử ghi nhận gửi thông báo/hiển thị/cập nhật thành công;
                            </p>

                            <p>
                                <b>13.2.</b> Nếu Người dùng muốn liên hệ với Dagoras liên quan đến quyền của mình
                                hoặc nếu có bất kỳ góp ý, phản hồi hoặc yêu cầu nào liên quan đến Điều khoản này,
                                thì sẽ liên hệ với Dagoras theo hướng dẫn sau đây:
                            </p>
                            <p>
                                - Gọi điện đến hotline: 024.6292.5308
                            </p>
                            <p>
                                - Gửi thư điện tử (email) theo địa chỉ: support@gocard.vn
                            </p>
                            <p>
                                - Liên hệ trực tiếp với chúng tôi hoặc gửi thư qua đường bưu điện, chuyển phát nhanh
                                tới địa chỉ: số 80 Duy Tân, phường Dịch Vọng Hậu, quận Cầu Giấy, thành phố Hà Nội
                            </p>
                            <p>
                                Dagoras sẽ xử lý các yêu cầu của Người dùng sớm nhất có thể trong khả năng và phạm
                                vi theo quy định của pháp luật.
                            </p>

                            <p>
                                <b>13.3.</b> Điều khoản này được xây dựng và cập nhật bởi Dagoras. Nếu Người dùng
                                chưa thực hiện việc xác nhận Đồng ý vì bất kỳ lý do nào, nhưng vẫn thực hiện việc
                                truy cập, đăng ký sử dụng Sản phẩm hợp tác, hoặc sử dụng các sản phẩm, dịch vụ do
                                Dagoras, Tổ chức tín dụng cung cấp sau thời điểm Điều khoản này được thông báo tới
                                Người dùng hoặc được điều chỉnh, cập nhật, sửa đổi, bổ sung, thay thế thì được hiểu
                                là Người dùng không có bất kỳ phản đối nào về Điều khoản này bao gồm cả Điều khoản
                                được điều chỉnh, cập nhật, sửa đổi, bổ sung, thay thế.
                            </p>

                            <p>
                                <b>13.4</b> Người dùng đồng ý rằng Dagoras có quyền sửa đổi, bổ sung, thay thế Điều
                                khoản này vào bất cứ thời điểm nào thông qua công bố, niêm yết, đăng tải, hiển thị,
                                cập nhật công khai trên Gocard và/hoặc gửi thông báo liên quan đến Điều khoản này
                                qua bất kỳ phương thức nào như SMS, cuộc gọi, email, ứng dụng di động, website, ...
                                Những sửa đổi, bổ sung Điều khoản sẽ là một phần không tách rời của Điều khoản. Điều
                                khoản thay thế sẽ có hiệu lực tùy theo cách thức thông báo/gửi theo Khoản 1 Điều
                                này.
                            </p>

                            <h2>ĐIỀU 14: ĐIỀU KHOẢN CHUNG </h2>

                            <p>
                                <b>14.1.</b> Điều khoản này được giải thích theo và điều chỉnh bởi pháp luật Việt
                                Nam.
                            </p>
                            <p>
                                <b>14.2.</b> Các mâu thuẫn phát sinh từ và liên quan đến Điều khoản này sẽ được giải
                                quyết thông qua đàm phán, thương lượng một cách thiện chí giữa Dagoras và Người
                                dùng để đảm bảo các quyền lợi hợp pháp của mỗi bên. Nếu mâu thuẫn không được giải
                                quyết trong vòng 60 ngày thì sẽ được giải quyết tại cơ quan tài phán có thẩm quyền
                                theo quy định pháp luật.
                            </p>

                        </div>
                    </div>
                </div>
                <ScrollToTopButton/>
            </div>
        </>
    );
}

export default React.memo(LicensePageView1);
