import React from 'react';
import "./CutomArrow.scss"

const CustomArrowRight = ({onClick}: any) => {

    return (
        <>
            <i className="custom-right-arrow" onClick={onClick}/>
        </>
    );
}

export default React.memo(CustomArrowRight);
