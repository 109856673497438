import React, {useEffect, useState} from 'react';
import {Layout, Col, Spin, Row} from 'antd';
import {useParams, useHistory, Link} from 'react-router-dom';
import publicService from "../../../service/PublicService";
import './BlogPageView.scss';
import ScrollToTopButton from "../../elements/ScrollToTopButton/ScrollToTopButton";

const {Content} = Layout;

interface Blog {
    id: number;
    title: string;
    imageUrl: string;
    summary: string;
    createdAt: string;
    updatedAt: string;
}

interface Category {
    id: number;
    name: string;
    acronymLink: string;
}

interface RouteParams {
    acronymLink?: string;
}

const BlogPageView: React.FC = () => {
    const {acronymLink} = useParams<RouteParams>();
    const history = useHistory();
    const [categoryBlog, setCategoryBlog] = useState<string | undefined>();
    const [blogs, setBlogs] = useState<Blog[]>([]);
    const [categories, setCategories] = useState<Category[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const [activeCategory, setActiveCategory] = useState<string | undefined>("");
    const [visibleCount, setVisibleCount] = useState<number>(3);

    const fetchCategories = async () => {
        setLoading(true);
        try {
            const response = await publicService.findAllCategory();
            setCategories(response.data);
        } catch (error) {
            console.error("Lỗi khi lấy dữ liệu categories:", error);
        } finally {
            setLoading(false);
        }
    };

    const fetchBlogsByCategory = async (acronymLink: string) => {
        setLoading(true);
        try {
            const resp = await publicService.findByCategoryAcronymLinkv2(acronymLink);
            setBlogs(resp.data)
        } catch (error) {
            console.error("Lỗi khi lấy dữ liệu blogs:", error);
        } finally {
            setLoading(false);
        }
    }

    const fetchBlogs = async () => {
        setLoading(true);
        try {
            const resp = await publicService.findAllBlog();
            setBlogs(resp.data);
        } catch (error) {
            console.error("Lỗi khi lấy dữ liệu blogs:", error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        document.title = "Gocard | Blog"

        fetchCategories();
    }, []);

    useEffect(() => {
        if (categoryBlog) {
            fetchBlogsByCategory(categoryBlog);
        } else {
            fetchBlogs();
        }
    }, [categoryBlog]);

    const handleCategoryClick = (event?: React.MouseEvent<HTMLAnchorElement>, categoryLink?: string) => {
        setActiveCategory(categoryLink);
        if (event) {
            event.preventDefault();
        }

        if (categoryLink !== acronymLink) {
            setCategoryBlog(categoryLink)
            history.push(`/blog/${categoryLink}`);
        } else {
            setCategoryBlog(undefined);
            history.push('/blog');
        }
    };

    const handleBlogClick = (blogId: number) => {
        history.push(`/blog-detail/${blogId}`);
    };

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB');
    };

    const handleSeeMore = () => {
        setVisibleCount(prevCount => prevCount + 3);
    };

    return (
        <>
            <div className="blogs-container">
                <Spin spinning={loading}>
                    <Layout>
                        <Content style={{padding: '0 10px', fontFamily: 'SVN-Regular'}}>
                            {/* Danh sách các liên kết category */}
                            <div style={{
                                margin: '16px 0',
                                maxWidth: '580px',
                                whiteSpace: 'nowrap',
                                height: '80px'
                            }} className="category-container">
                                <Link to="/blog" onClick={(event) => handleCategoryClick(event, "")}
                                      className={`category-item ${activeCategory === "" ? "active" : ""}`}>Tất cả</Link>
                                {categories.map((category) => (
                                    <span key={category.id} style={{display: 'inline-block'}}>
                                        <span
                                            className={`category-item ${activeCategory === category.acronymLink ? "active" : ""}`}
                                            onClick={() => handleCategoryClick(undefined, category.acronymLink)}
                                            style={{marginRight: '10px', cursor: 'pointer'}}
                                        >
                                            {category.name}
                                        </span>
                                    </span>
                                ))}
                            </div>

                            {/* Danh sách blogs */}
                            <div className="list-blog-container">
                                {blogs.length > 0 ? (
                                    <>
                                        {blogs.slice(0, visibleCount).map(blog => (
                                            <Row key={blog.id} gutter={[16, 16]} className="blog-row"
                                                 onClick={() => handleBlogClick(blog.id)}>
                                                <Col span={6} className="blog-image-container">
                                                    <img
                                                        alt="blog cover"
                                                        src={blog.imageUrl || "/default-image.png"}
                                                        className="blog-image"
                                                    />
                                                </Col>
                                                <Col span={18} className="blog-content-container">
                                                    <div className="blog-title-container">
                                                        <h3 className="blog-title">{blog.title}</h3>
                                                    </div>
                                                    <div className="blog-summary">
                                                        {blog.summary}
                                                    </div>
                                                    <div className="blog-details">
                                                        <span
                                                            className="blog-created">{formatDate(blog.createdAt)}</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        ))}
                                        {/* Hiển thị nút "Xem thêm" nếu còn blog để hiển thị */}
                                        {visibleCount < blogs.length && (
                                            <a className='see-more' onClick={handleSeeMore}>Xem thêm -&gt;</a>
                                        )}
                                    </>
                                ) : (
                                    <p>Không có blog nào được hiển thị</p>
                                )}
                            </div>
                        </Content>
                    </Layout>
                    <ScrollToTopButton/>
                </Spin>
            </div>
        </>
    );
};

export default React.memo(BlogPageView);