import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import './CardDetailPageView1.scss';
import publicService from "../../../service/PublicService";
import {Spin} from "antd";
import ScrollToTopButton from "../../elements/ScrollToTopButton/ScrollToTopButton";

interface LocationState {
    cardItem: any;
    partner: string;
}

interface CreditCard {
    id: number;
    name: string;
    imageUrl: string;
    creditLimitFrom: number;
    creditLimitTo: number;
    annualFee: number;
    monthlyIncome: number;
    partner: string;
    navigateLink: string;
}

const CardDetailPageView1 = () => {
    const [banner, setBanner] = useState<string | null>(null);
    const [colorBody, setColorBody] = useState<string>('#eefd8c');
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const location = useLocation<LocationState>();
    const {cardItem, partner} = location.state || {};

    useEffect(() => {
        if (partner === 'vpbank') {
            document.body.classList.add('partner-vp-detail')
        } else if (partner === 'vib') {
            document.body.classList.add('partner-vib-detail')
        } else if (partner === 'tpbank') {
            document.body.classList.add('partner-tp-detail')
        } else {
            document.body.classList.add(':root');
        }

        let userId = sessionStorage.getItem('user_id');
        if (!userId) {
            sessionStorage.setItem('user_id', crypto.randomUUID());
        }
        let bannerTmp = sessionStorage.getItem('bannerPartnerUrl');
        if(bannerTmp!=null) setBanner(bannerTmp);
        let colorBodyTmp = sessionStorage.getItem('colorBody');
        if(colorBodyTmp!=null) setColorBody(colorBodyTmp);
        window.scroll(0, 0);
    }, [partner]);

    let features: string[] = [];
    if (typeof cardItem.features === 'string') {
        try {
            features = JSON.parse(cardItem.features);
        } catch (e) {
            console.error('Error parsing features:', e);
        }
    } else {
        features = cardItem.features || [];
    }

    const handleCreateCard = async (step: string, type: string, cardItem: CreditCard) => {
        setLoading(true);
        try {
            const token = crypto.randomUUID();
            sessionStorage.setItem('token', token);

            const cardData = {
                product: cardItem.name,
                creditLimit: cardItem.creditLimitTo,
                monthlyIncome: cardItem.monthlyIncome,
            };

            sessionStorage.setItem('cardData', JSON.stringify(cardItem));

            const resp = await publicService.applyRegister(step, type, cardData);
            if (resp.status === 200) {
                history.push('/contact-info/credit-card', {cardItem: cardData});
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    function formatNumberCustom(number: number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    return (
        <>
            <Spin spinning={loading}>
                <div className="container">
                    {banner && (
                        <div className="banner-container">
                            <div>
                                <img src={banner} alt="Partner Banner"/>
                            </div>
                        </div>
                    )}
                    <p className="card-detail-header">Chi tiết thẻ</p>
                    <div style={{display: 'grid', justifyContent: 'center', alignItems: 'center'}}>
                        <div className="border border-2 px-4 py-4 card-wrapper"
                             style={{borderColor: '', backgroundColor: colorBody}}>
                            <p className="fw-bold card-name">{cardItem.name}</p>
                            <div className="d-flex flex-row justify-content-around align-item">
                                <img
                                    className={
                                    `img-card ${
                                        cardItem.name.toLowerCase().includes('tpbank evo') ? 'tp-img' :
                                            cardItem.name.toLowerCase().includes('vpbank z') ? 'vp-img' :
                                                cardItem.name.toLowerCase().includes('super shopee platinum') ? 'vp-img' : ''
                                    }`
                                }
                                    src={cardItem.imageUrl} alt="card"/>
                                <div className="ps-2 open-card">
                                    <div className="button-link-container">
                                        <button
                                            className="rounded-pill open-card-btn"
                                            onClick={() => handleCreateCard("dang-ky-the-tin-dung", 'credit-card', cardItem)}
                                        >Mở thẻ ngay
                                        </button>
                                        <a className='back' href={partner ? `/card?partner=${partner}` : '/card'}>
                                            &lt; Quay lại
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="container card-detail-grid">
                                <div className="row px-0">
                                    {/* {cardItem.map(() => ( */}
                                    <div
                                        className="col-12 d-grid justify-content-between py-2 pt-2 px-0 card-detail-info">
                                        <div className="text-start right-col">Loại thẻ</div>
                                        <div className="text-start left-col">
                                            {cardItem.cardType}
                                        </div>
                                    </div>
                                    <div
                                        className="col-12 d-grid justify-content-between py-2 pt-2 px-0 card-detail-info">
                                        <div className="text-start right-col">Phí thường niên</div>
                                        <div className="text-start left-col">
                                            {formatNumberCustom(cardItem.annualFee)}
                                        </div>
                                    </div>
                                    <div
                                        className="col-12 d-grid justify-content-between py-0 pt-2 px-0 card-detail-info">
                                        <div className="text-start right-col">Lãi suất năm</div>
                                        <div className="text-start left-col">
                                            {cardItem.interestRate}
                                        </div>
                                    </div>
                                    <div
                                        className="col-12 d-flex justify-content-between py-2 pt-4 px-0 card-detail-info">
                                        <div className="text-start right-col">Tính năng nổi bật</div>
                                    </div>
                                    {/* ))} */}
                                </div>
                            </div>
                            <div className="container-details-card">
                                <div>
                                    {features.map((feature: string, index: number) => (
                                        <div
                                            key={index}
                                            className={`text-start d-grid tag ${index === 0 ? "first-feature" : ""
                                            }`}
                                        >
                                            <div
                                                className="fw-bold"
                                                dangerouslySetInnerHTML={{__html: feature}}
                                            ></div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTopButton/>
            </Spin>
        </>
    );
};

export default React.memo(CardDetailPageView1);
