import React, { useState, useEffect } from 'react';
import cookieIcon from '@assets/icon/cookie.svg';
import { Button, Modal } from 'antd';
import './ModalView.scss';
import LicensePageView1 from '../LicensePageView/LicensePageView1';
const ModalView = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isDetailModalVisible, setIsDetailModalVisible] = useState(false);
    const [isBannerShow, setIsBannerShow] = useState(false);

    useEffect(() => {
        if(window.location.href.indexOf("partner")>0){
            localStorage.setItem("acceptCookie",'true')
        }
        const localStorageInfo = localStorage.getItem("acceptCookie");
        let link =  sessionStorage.getItem("link");
        if(link===null) link="";
        else link = link.toLowerCase()
        if (!localStorageInfo) {
            setIsModalVisible(true);
        }else {
            if(window.location.href.toLowerCase().indexOf("tnex")<0 &&  link.indexOf("tnex")<0 &&
                window.location.href.toLowerCase().indexOf("homecredit")<0 && link.indexOf("homecredit")<0) {
                setIsBannerShow(false);
            }
        }
    }, [
    ]);

    const handleOk = () => {
        localStorage.setItem("acceptCookie", 'true');
        setIsModalVisible(false);
        setIsDetailModalVisible(false);
        let link =  sessionStorage.getItem("link");
        if(link===null) link="";
        if(window.location.href.indexOf("tnex")<0 &&  link.indexOf("tnex")<0 &&
            window.location.href.indexOf("HomeCredit")<0 && link.indexOf("HomeCredit")<0) {
            setIsBannerShow(false);
        }
    };

    const showDetailModal = (e: any) => {
        e.preventDefault();
        setIsModalVisible(false);
        setIsDetailModalVisible(true);
    };

    return (
        <>
            <Modal
                visible={isModalVisible}
                closable={false}
                maskClosable={false}
                keyboard={false}
                className="custom-modal"
                footer={null}
                style={{ fontFamily: 'SVN-Regular' }}
            >
                <div className="header">
                    <img src={cookieIcon} alt="cookie-icon" />
                    <p>Điều khoản bảo mật dữ liệu</p>
                </div>
                <div className="body">
                    <div className="text-modal">
                        <p>
                            Chúng tôi thu thập một số dữ liệu cookies và dữ liệu truy cập để trang web có thể giúp bạn tìm được sản phẩm, dịch vụ và ưu đãi phù hợp với bạn. Ngoài ra, nó cũng giúp chúng tôi đo lường hiệu quả vận hành, tối ưu trải nghiệm người dùng và quảng cáo.
                        </p>
                    </div>
                    <p>Bằng việc chọn <b>“Tiếp tục”</b>, Quý khách đồng ý cho Gocard.vn xử lý dữ liệu theo các
                        <a href='' onClick={showDetailModal}>mục đích và điều khoản</a> của website.</p>
                </div>
                <div className="modal-footer">
                    <Button key="submit" type="primary" className="btn-continue" onClick={handleOk}>
                        Tiếp tục
                    </Button>
                </div>
            </Modal>
            <Modal
                visible={isBannerShow}
                closable={false}
                keyboard={false}
                className="custom-modal banner"
                footer={null}
                style={{ fontFamily: 'SVN-Regular' }}
            >
                    <Button className="icon-general-close btn-close" onClick={()=>{setIsBannerShow(false)}}>
                    </Button>
                <a href="https://zalo.me/2296886608849173374" target="_blank" onClick={()=>{setIsBannerShow(false)}}>
                    <img width="100%" src="https://img.dangky.info/gocard/banner.png"/>
                </a>
            </Modal>

            {/* Modal chi tiết */}
            <Modal
                visible={isDetailModalVisible}
                closable={false}
                maskClosable={false}
                keyboard={false}
                className="custom-modal"
                footer={null}
                style={{ fontFamily: 'SVN-Regular' }}
            >
                <div className="header">
                    <img src={cookieIcon} alt="cookie-icon" />
                    <p>Điều khoản bảo mật dữ liệu</p>
                </div>
                <div className="body">
                    <div className="text-body">
                        <LicensePageView1 />
                    </div>
                    <span className='text-agree'>Bằng việc chọn <b>“Tiếp tục”</b>, Quý khách đồng ý cho Gocard.vn xử lý dữ liệu theo các mục đích và điều khoản của website.</span>
                </div>
                <div className="modal-footer">
                    <Button key="submit" type="primary" className="btn-continue" onClick={handleOk}>
                        Tiếp tục
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default React.memo(ModalView);
