import React from 'react';
import "./Radio.scss";
import warning from "@assets/images/icon-warning.png";

interface RadioProps {
    name: string;
    label: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    required: boolean;
    value?: string;
    width1?: string;
    width2?: string;
    value1: string;
    value2: string;
    error: string;
}

const Radio: React.FC<RadioProps> = ({
                                         name,
                                         label,
                                         onChange,
                                         required,
                                         width1,
                                         width2,
                                         value,
                                         value1,
                                         value2,
                                         error
                                     }) => {
    return (
        <>
            <div className={`radio-group ${error ? 'mb-5' : 'mb-3'}`}>
                <label htmlFor={name}>{label} {required && <span>(<span className="fire_engine_red">*</span>)</span>}
                </label>
                <div className="radio_group border_input" id={name}>
                    <label className="radio_item" style={{width: width1}}>
                        <input type="radio"
                               checked={value1 === value}
                               data-gtm-form-interact-field-id="1"
                               name={name}
                               value={value1}
                               onChange={onChange}/>
                        <span>{value1}</span>
                    </label>
                    <label className="radio_item" style={{width: width2}}>
                        <input type="radio"
                               checked={value2 === value}
                               data-gtm-form-interact-field-id="2"
                               name={name}
                               value={value2}
                               onChange={onChange}/>
                        <span>{value2}</span>
                    </label>
                </div>
                {error &&
                <p className="error_show">
                    <img src={warning} alt={"warning"}/>
                    {error}
                </p>
                }
            </div>
        </>
    )
};

export default React.memo(Radio);
