import React, {useEffect, useState} from 'react';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {notification, Spin} from 'antd';
import './ContactInfoPageView.scss';
import publicService from "../../../service/PublicService";

interface RouteParams {
    type: string;
}

interface ContactInfoState {
    inputName: string;
    phone: string;
    inputCccd: string;
    emailUsername: string;
    emailDomain: string;
    validName: boolean;
    validPhone: boolean;
    validCccd: boolean;
    validEmail: boolean;
    errorMail: string;
}

interface CreditCard {
    name: string;
    creditLimitTo: number;
    monthlyIncome: number;
}

interface LocationState {
    cardItem: CreditCard;
    partner?: string;
}

const ContactInfoPageView: React.FC = () => {
    window.scrollTo(0, 0);
    const {type} = useParams<RouteParams>();
    const history = useHistory();
    const [api, contextHolder] = notification.useNotification();
    const location = useLocation<LocationState>();
    const {cardItem, partner} = location.state || {};

    const [contactInfo, setContactInfo] = useState<ContactInfoState>({
        inputName: '',
        phone: '',
        inputCccd: '',
        emailUsername: '',
        emailDomain: 'gmail.com',
        validName: true,
        validPhone: true,
        validCccd: true,
        validEmail: true,
        errorMail: '',
    });

    const [emailDomains, setEmailDomains] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setContactInfo((prevState) => ({
            ...prevState,
            inputName: value,
            validName: value.trim() !== ''
        }))
    };

    const handleChangePhoneNo = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const regex = /^\d{0,10}$/;
        if (value.length >= 11) {
            return;
        }
        if (regex.test(value)) {
            setContactInfo((prevState) => ({
                ...prevState,
                phone: value,
                validPhone: value.length === 10 && /^\d{10}$/.test(value),
            }));
        }
    };

    const handleCccdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const regex = /^\d{12}$/;
        if (/^\d*$/.test(value) && value.length <= 12) {
            setContactInfo((prevState) => ({
                ...prevState,
                inputCccd: value,
                validCccd: value.length === 12 && regex.test(value)
            }));
        }
    };

    const handleChangeEmailUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const regex = /^[A-Za-z0-9]*$/; // Only allows letters and numbers
        setContactInfo((prevState) => ({
            ...prevState,
            emailUsername: value,
        }));

        if (regex.test(value)) {
            setContactInfo((prevState) => ({
                ...prevState,
                errorMail: '',
                validEmail: true,
            }));
        } else {
            setContactInfo((prevState) => ({
                ...prevState,
                errorMail: 'Tên email không được chứa ký tự đặc biệt',
                validEmail: false,
            }));
        }
    };

    const handleChangeEmailDomain = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        setContactInfo((prevState) => ({
            ...prevState,
            emailDomain: value,
        }));
    };

    const handleEmailBlur = async () => {

        const email = `${contactInfo.emailUsername.trim()}@${contactInfo.emailDomain.trim()}`;

        if (contactInfo.emailUsername.trim() === '') {
            setContactInfo((prevState) => ({
                ...prevState,
                validEmail: false,
                errorMail: 'Vui lòng nhập tên email',
            }));
            return;
        }

        try {

            const response = await publicService.verifyEmail({email});

            if (response.data.isValid) {
                sessionStorage.setItem('userEmail', email);
                setContactInfo((prevState) => ({
                    ...prevState,
                    validEmail: true,
                    errorMail: '',
                }));
            } else {
                setContactInfo((prevState) => ({
                    ...prevState,
                    validEmail: false,
                    errorMail: 'Email không hợp lệ hoặc không thể gửi được.',
                }));
            }
        } catch (error) {
            console.error('API Error:', error);
            setContactInfo((prevState) => ({
                ...prevState,
                validEmail: false,
                errorMail: 'Đã xảy ra lỗi khi xác thực email. Vui lòng thử lại sau.',
            }));
        }
    };

    const handleOnSubmitCard = async (step: string) => {
        const {inputName, phone, inputCccd, emailUsername, emailDomain, validName, validPhone, validCccd, validEmail} = contactInfo;

        if (!validName || !validPhone || !validCccd || !validEmail) {
            return;
        }

        if (inputName === '' || phone === '' || inputCccd === '' || emailUsername === '') {
            if (inputName === '') {
                setContactInfo((prevState) => ({
                    ...prevState,
                    validName: false,
                }));
            }
            if (phone === '') {
                setContactInfo((prevState) => ({
                    ...prevState,
                    validPhone: false,
                }));
            }
            if (inputCccd === '') {
                setContactInfo((prevState) => ({
                    ...prevState,
                    validCccd: false,
                }));
            }
            if (emailUsername === '') {
                setContactInfo((prevState) => ({
                    ...prevState,
                    validEmail: false,
                    errorMail: 'Vui lòng nhập tên email',
                }));
            }
            return;
        }

        const leadDto = {
            fullName: inputName,
            phone: phone,
            identityNumber: inputCccd,
            email: `${emailUsername}@${emailDomain}`,
            product: cardItem?.name,
            creditLimit: cardItem?.creditLimitTo,
            monthlyIncome: cardItem?.monthlyIncome,
        };

        setLoading(true);
        const existingLoanInfo = sessionStorage.getItem('loanInfo');

        // Nếu có loanInfo rồi thì tiếp tục lưu vào sessionStorage
        const loanInfo = existingLoanInfo ? JSON.parse(existingLoanInfo) : {};
        loanInfo.fullName = inputName;
        loanInfo.identityNumber = inputCccd;
        loanInfo.phone = phone;
        sessionStorage.setItem('loanInfo', JSON.stringify(loanInfo));

        try {
            const response = await publicService.applyRegister(step, type, leadDto);

            if (response.status === 200) {
                if (partner) {
                    history.push({
                        pathname: `/info/${type}`,
                        state: {
                            phone: phone,
                            emailUsername: emailUsername,
                            emailDomain: emailDomain,
                            partner: partner,
                        },
                        search: `?partner=${partner}`,
                    });
                } else {
                    history.push(`/info/${type}`, {
                        phone: phone,
                        emailUsername: emailUsername,
                        emailDomain: emailDomain,
                    });
                }
                return;
            }
        } catch (error: any) {
            api.error({
                message: 'Error',
                description: error.response.data,
            });
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        setLoading(true);

        try {
            await handleEmailBlur();

            if (!contactInfo.validEmail) {
                return;
            }

            await handleOnSubmitCard('nhap-thong-tin-lien-he');
        } catch (error) {
            console.error("Error during form submission:", error);
        } finally {
            setLoading(false);
        }
    };

    const fetchEmailDomains = async () => {
        setLoading(true);
        try {
            const response = await publicService.getAllEmailDomains();
            if (response.status === 200) {
                setEmailDomains(response.data);
            }
        } catch (error) {
            console.error("Error fetching email domains:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        document.title = "Gocard | Nhập thông tin liên hệ"

        fetchEmailDomains();
    }, []);

    return (
        <>
            {contextHolder}
            <div className="contact-form">
                <Spin spinning={loading}>
                    <p className="title-page" style={{fontWeight: 1000, marginBottom: '0.2em'}}>
                        Nhập thông tin liên hệ
                    </p>
                    {/*<p className="svn-font" style={{fontSize: '14px', fontWeight: '400'}}>*/}
                    {/*    Vui lòng điền số điện thoại và email của bạn để chúng tôi tìm những đối tác tốt nhất cho bạn*/}
                    {/*</p>*/}
                    <div className="mt-4 mb-3 contact-input">
                        <p className="fw-semibold m-0 svn-bold" style={{fontSize: '14px', fontWeight: '600'}}>
                            Họ và Tên *
                        </p>
                        <input
                            type="text"
                            className="form-control border border-1"
                            id="name"
                            placeholder="Vui lòng nhập"
                            value={contactInfo.inputName}
                            onChange={handleNameChange}
                            required
                        />
                        {!contactInfo.validName && (<p className='text-danger'>Vui lòng nhập họ và tên</p>)}
                    </div>
                    <div className="mb-3 contact-input">
                        <p className="fw-semibold m-0 svn-bold" style={{fontSize: '14px', fontWeight: '600'}}>
                            Số điện thoại *
                        </p>
                        <input
                            type="text"
                            className="form-control border border-1"
                            id="phoneNumber"
                            pattern="\d*"
                            placeholder="Vui lòng nhập"
                            value={contactInfo.phone}
                            onChange={handleChangePhoneNo}
                        />
                        {!contactInfo.validPhone && (
                            <p className="text-danger">Vui lòng nhập 10 chữ số</p>
                        )}
                    </div>
                    <div className="mb-3 contact-input">
                        <p className="fw-semibold m-0 svn-bold" style={{fontSize: '14px', fontWeight: '600'}}>
                            Căn cước công dân *
                        </p>
                        <input
                            type="text"
                            className="form-control border border-1"
                            id="CCCD"
                            pattern="\d*"
                            placeholder="Vui lòng nhập"
                            value={contactInfo.inputCccd}
                            onChange={handleCccdChange}
                            maxLength={12}
                            required
                        />
                        {!contactInfo.validCccd && (
                            <p className='text-danger'>Vui lòng nhập 12 kí tự CCCD/CMND</p>)}
                    </div>
                    <div className="mb-3 contact-input">
                        <p className="fw-semibold m-0 svn-bold" style={{fontSize: '14px', fontWeight: '600'}}>
                            Email *
                        </p>
                        <div className="d-flex align-items-center">
                            <input
                                type="text"
                                className="form-control border border-black"
                                placeholder="Tên email"
                                value={contactInfo.emailUsername}
                                onChange={handleChangeEmailUsername}
                                onBlur={handleEmailBlur}
                            />
                            <select
                                className="form-control border border-black ms-2"
                                value={contactInfo.emailDomain}
                                onChange={handleChangeEmailDomain}
                            >
                                {emailDomains.map((domain: any) => (
                                    <option key={domain.id} value={domain.value}>
                                        {domain.value}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {!contactInfo.validEmail && <p className="text-danger">{contactInfo.errorMail}</p>}
                    </div>
                    <div className="d-flex justify-content-center">
                        <button
                            className="btn-search-loan-card"
                            style={{backgroundColor: 'black'}}
                            onClick={handleSubmit}>
                            {/*{type === 'credit-card' ? 'Tìm thẻ phù hợp' : 'Tìm khoản vay phù hợp'}*/}
                            Xác nhận
                        </button>
                    </div>
                </Spin>
            </div>
        </>
    );
};

export default React.memo(ContactInfoPageView);
