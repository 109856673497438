import React from 'react';
import "./CutomArrow.scss"

const CustomArrowLeft = ({onClick}: any) => {

    return (
        <>
            <i className="custom-left-arrow" onClick={onClick}/>
        </>
    );
}

export default React.memo(CustomArrowLeft);
