import React from 'react';
import "./Input2.scss";
import warning from "@assets/images/icon-warning.png";
import {IonIcon} from "@ionic/react";

interface InputProps {
    name: string;
    type: string;
    label: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    required: boolean;
    icon: any;
    placeholder: string;
    value: string;
    error: string;
    maxLength?: number;
    minLength?: number;
}

const Input2: React.FC<InputProps> = ({
                                          name,
                                          type,
                                          label,
                                          onChange,
                                          icon,
                                          required,
                                          placeholder,
                                          value,
                                          error,
                                          minLength,
                                          maxLength
                                      }) => {
    return (
        <>
            <div className={`form__group field ${error ? 'mb-5' : 'mb-3'} ${value ? 'is-focused' : ''}`}>
                <IonIcon className="icon-input-1" icon={icon}/>
                <input id={name}
                       type={type}
                       name={name}
                       onChange={onChange}
                       value={value}
                       placeholder={placeholder}
                       minLength={minLength}
                       maxLength={maxLength}
                       className="form__field"
                       autoComplete="off"/>
                <label htmlFor={name} className="form__label">{label} {required &&
                <span>(<span className="fire_engine_red">*</span>)</span>}</label>
                {error &&
                <p className="error_show">
                    <img src={warning} alt={"warning"}/>
                    {error}
                </p>
                }
            </div>
        </>
    )
};

export default React.memo(Input2);
