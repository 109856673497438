import React, {useEffect, useState} from 'react';
import './SentPhoneOTPPageView.scss';
import phoneOtp from '@assets/icon/phone-otp.svg';
import warningIcon from '@assets/icon/warning-icon.svg';
import {useLocation, useParams, useHistory} from 'react-router-dom';
import publicService from 'src/app/service/PublicService';
import {notification, Spin} from 'antd';
/*import {callReceiver} from "../../../service/SendDataToPartner";*/

interface LocationState {
    phone: any;
    email: any;
    partner?: string;
    cardItem: any;
    cardData: any;
    typeOTP?: string;
}

interface RouteParams {
    type: string;
}

const SentPhoneOTPPageView = () => {
    const history = useHistory();

    // Lấy thời gian gửi OTP từ sessionStorage
    const otpSentTime = parseInt(sessionStorage.getItem('otpSentTime') || '0');
    const timeElapsed = Math.floor((Date.now() - otpSentTime) / 1000);
    const [loading, setLoading] = useState(false);

    // Thời gian bắt đầu đếm ngược (120 giây) trừ đi thời gian đã trôi qua
    const initialCounter = 120 - timeElapsed;
    const [counter, setCounter] = useState(initialCounter > 0 ? initialCounter : 0);
    const [canResend, setCanResend] = useState(counter <= 0);
    const location = useLocation<LocationState>();
    const {phone, email, partner, cardItem, cardData, typeOTP} = location.state || {};
    const [inputPhoneOtp, setInputPhoneOtp] = useState('');
    const [warningInputPhoneOtp, setWarningInputPhoneOtp] = useState('');
    const [api, contextHolder] = notification.useNotification();
    const [isOtpValid, setIsOtpValid] = useState(false);


    // Lấy partner từ chuỗi truy vấn (nếu có)
    const query = new URLSearchParams(location.search);
    const partnerFromQuery = query.get('partner');

    // Xác định giá trị partner cuối cùng
    const finalPartner = partner || partnerFromQuery;

    const {type} = useParams<RouteParams>();

    const lastThreeChars = phone.substring(phone.length - 3);
    const [suffixNoti, setSuffixNoti] = useState('');

    useEffect(() => {
        document.title = "Gocard | Gửi mã OTP"
        if(typeOTP=='phone'){
            setSuffixNoti('qua số điện thoại *****'+lastThreeChars)
        }else if(typeOTP=='zalo'){
            setSuffixNoti('qua số tài khoản zalo *****'+lastThreeChars)
        }else if(typeOTP=='email'){
            setSuffixNoti('qua số email là *****'+email.split("@")[0].substring(email.split("@")[0].length -3) + email.split("@")[1])
        }
    }, []);

    useEffect(() => {
        if (counter > 0) {
            const timer = setTimeout(() => setCounter(counter - 1), 1000);
            return () => clearTimeout(timer);
        } else {
            setCanResend(true);
        }
    }, [counter]);

    const handleResendClick = async (step: string) => {
        if (canResend) {
            const sessionKey = type === 'card' ? 'cardInfo' : 'loanInfo';

            let storedInfo = sessionStorage.getItem(sessionKey);
            if (storedInfo) {
                let infoObj = JSON.parse(storedInfo);
                sessionStorage.setItem(sessionKey, JSON.stringify(infoObj));
            }
            try {
                const resp = await publicService.applyRegister(step, type, {typeOTP: typeOTP});
                if (resp.status === 200) {
                    api.success({
                        message: 'Thành công',
                        description: 'Đã gửi lại mã xác nhận'
                    });
                }
            } catch (error: any) {
                api.error({
                    message: 'Error',
                    description: error.response.data,
                });
            }

            // Cập nhật thời gian gửi OTP và đặt lại bộ đếm
            const currentTime = Date.now();
            sessionStorage.setItem('otpSentTime', currentTime.toString());
            setCounter(120);
            setCanResend(false);
        }
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);
        try {
            handleResendClick('gui-otp');
        } catch (error) {
            console.log('Error', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmitOtp = async (step: string) => {
        setLoading(true);
        if (inputPhoneOtp === '') {
            setIsOtpValid(false);
            return;
        }
        if (!isOtpValid) {
            return;
        }
        try {
            const resp = await publicService.applyRegister(step, type, {otpRequest: inputPhoneOtp,typeOTP:typeOTP});
            if (resp.status === 200) {
                if (type === 'credit-card') {
                    history.push({
                        pathname: `/success`,
                        state: {partner: finalPartner, cardItem: cardItem, cardData: cardData},
                        search: finalPartner ? `?partner=${finalPartner}` : '',
                    });
                } else {
                    /*if(finalPartner==null || finalPartner.toLowerCase().indexOf("tnex")>=0){
                        await callReceiver();
                    }*/
                    history.push({
                        pathname: `/success-view`,
                        state: {partner: finalPartner},
                        search: finalPartner ? `?partner=${finalPartner}` : '',
                    });

                }
            }
        } catch (error: any) {
            api.error({
                message: 'Lỗi',
                description: error.response.data,
            });
        } finally {
            setLoading(false);
        }
    };

    const handleSubmitOtpValid = (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);
        try {
            handleSubmitOtp('xac-thuc-otp');
        } catch (error) {
            console.log('Error', error);
        } finally {
            setLoading(false);
        }
    };

    const handleInputPhoneOtp = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value.length > 6) {
            setWarningInputPhoneOtp('Không vượt quá 6 số.');
            return;
        }

        // Kiểm tra nếu giá trị chỉ chứa số và không dài hơn 6 ký tự
        if (/^\d*$/.test(value) && value.length <= 6) {
            setInputPhoneOtp(value);
            setWarningInputPhoneOtp('');

            // Kiểm tra tính hợp lệ của OTP (giả sử mã hợp lệ là chuỗi 6 chữ số)
            if (value.length === 6) {
                setIsOtpValid(true);
            } else {
                setIsOtpValid(false);
            }
        } else {
            setInputPhoneOtp(prevValue => prevValue); // Không thay đổi giá trị nếu không hợp lệ
            setWarningInputPhoneOtp('Không vượt quá 6 số.');
            setIsOtpValid(false); // Không hợp lệ
        }
    };

    const handleBlur = () => {
        if (inputPhoneOtp.length > 6) {
            setWarningInputPhoneOtp('Không vượt quá 6 số.');
        } else {
            setWarningInputPhoneOtp('');
        }
    };

    return (
        <>
            {contextHolder}
            <Spin spinning={loading}>
                <div className="container-sent-otp">
                    <img className='img-phone-otp' src={phoneOtp} alt="phone-otp"/>
                    <span className='sent-otp'>Đã gửi mã OTP</span>
                    <span className='phone-otp'>
                  Nhập mã OTP đã được gửi {suffixNoti}
                </span>
                    <div className="otp-input-container">
                        <input
                            type="password"
                            placeholder='Nhập mã OTP ...'
                            id='inputPhoneOtp'
                            value={inputPhoneOtp}
                            onChange={handleInputPhoneOtp}
                            onBlur={handleBlur}
                            pattern="\d*" // Chỉ cho phép nhập số
                        />
                        <div style={{display:counter==0?'none':'block'}}>
                        <div className="counter">
                            <div className="loading-spinner"></div>
                            <span>{`0${Math.floor(counter / 60)}:${counter % 60 < 10 ? '0' : ''}${counter % 60}`}</span>
                        </div>
                        </div>
                    </div>
                    {warningInputPhoneOtp &&
                        <div className="warning-message-phoneOtp">
                            {warningInputPhoneOtp}
                        </div>
                    }
                    <span className='failed-otp'>Bạn không nhận được mã?
                  <a onClick={handleSubmit} className={canResend ? '' : 'disabled-link'}>
                    Gửi lại ngay
                  </a>
                </span>
                    <div className="button-container-otp">
                        <button className="btn-continue-otp" onClick={handleSubmitOtpValid}>
                            Tiếp tục
                        </button>
                    </div>
                    <div className='warning-text'>
                        <img src={warningIcon} alt="warning-icon"/>
                        <span>
                        <b>KHÔNG CHIA SẺ MÃ OTP VỚI BẤT KỲ AI</b><br/>
                        Ngay cả những người tự xưng là nhân viên Gocard. Nếu có được mã này, họ có thể truy cập vào tài khoản của bạn
                    </span>
                    </div>
                </div>
            </Spin>
        </>
    );
}

export default React.memo(SentPhoneOTPPageView);
