import { Spin } from 'antd';
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Turnstile from 'react-turnstile';
import publicService from 'src/app/service/PublicService';
import ScrollToTopButton from "../../elements/ScrollToTopButton/ScrollToTopButton";

interface LoanItem {
    id: number;
    name: string;
    imageUrl: string;
    loanLimit: number;
    loanLimitFrom: number;
    term: number;
    fromTerm: number;
    interestRate: number;
    benefits: number;
}

const RegisterLoanPageView: React.FC = () => {
    const [loanAmount, setLoanAmount] = useState<number>(10000000);
    const [loanTerm, setLoanTerm] = useState<number>(6);
    const [agreement, setAgreement] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [checkCaptcha, setCheckCaptcha] = useState(true);

    const location = useLocation<{ loanItem: LoanItem }>();
    const history = useHistory();

    const loanItem = location.state?.loanItem;

    if(loanTerm < loanItem.fromTerm){
        setLoanTerm(loanItem.fromTerm)
    }
    const loanLimitInVND = loanItem?.loanLimit * 1000000;
    const loanLimitInVNDFrom = loanItem?.loanLimitFrom * 1000000;
    if(loanAmount<loanLimitInVNDFrom){
        setLoanAmount(loanLimitInVNDFrom);
    }

    const [backgroundHeader, setBackgroundHeader] = useState("#D9FC00");
    const [colorHeader, setColorHeader] = useState("#000000");


    useEffect(() => {
        if (!loanItem) {
            history.push('/');
        }
        let backgroundHeaderTmp = sessionStorage.getItem('background-header');
        if(backgroundHeaderTmp!=null) setBackgroundHeader(backgroundHeaderTmp)
        let colorHeaderTmp = sessionStorage.getItem('color-header');
        if(colorHeaderTmp!=null) setColorHeader(colorHeaderTmp)

    }, [loanItem, history]);

    const formatNumberWithCommas = (number: number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const formattedLoanAmount = formatNumberWithCommas(loanAmount);

    const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value, 10);
        setLoanAmount(value);
    };

    const LoanAmountBackground = `linear-gradient(
        to right,
        #000 ${((loanAmount - loanLimitInVNDFrom) / (loanItem.loanLimit * 1000000 - loanLimitInVNDFrom)) * 100}%,
        #aaa ${((loanAmount - loanLimitInVNDFrom) / (loanItem.loanLimit * 1000000 - loanLimitInVNDFrom)) * 100}%
    )`;

    const LoanTermBackground = `linear-gradient(
        to right,
        #000 ${((loanTerm - loanItem?.fromTerm) / (loanItem.term - loanItem?.fromTerm)) * 100}%,
        #aaa ${((loanTerm - loanItem?.fromTerm) / (loanItem.term - loanItem?.fromTerm)) * 100}%
    )`;

    const handleTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLoanTerm(parseInt(e.target.value, 10));
    };

    const handleAgreementChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAgreement(e.target.value);
    };

    const  handleSearch = async (step: string, type: string) => {
        if (agreement === 'agree') {
            const link = sessionStorage.getItem('link');
            const loanInfo = {
                ...loanItem,
                loanAmount,
                loanTerm,
                link
            };
            sessionStorage.setItem('loanInfo', JSON.stringify(loanInfo));
            setLoading(true);
            try {
                const resp = await publicService.applyRegister(step, type, loanInfo);
                if (resp.status === 200) {
                    history.push(`/contact-info/loan`);
                }
            } catch (error) {
                console.error("Lỗi khi đăng ký khoản vay:", error);
            } finally {
                setLoading(false);
            }
        } else {
            alert("Bạn phải đồng ý với Điều khoản sử dụng để tiếp tục.");
        }
    };

    const verifyCaptcha = (token: string) => {
        sessionStorage.setItem('token', token);
        setCheckCaptcha(false);
    };

    if (!loanItem) {
        return null;
    }

    return (
        <>
            <Spin spinning={loading}>
                <div className="loan-container" style={{marginTop: '40px'}}>
                    <h2 className="loan-title" style={{background: backgroundHeader, color: colorHeader}}>ĐĂNG KÝ KHOẢN VAY</h2>
                    <div className="loan-amount">
                        <label htmlFor="customRange1" className="form-label">
                            <span>Số tiền cần vay</span>
                            <input
                                type="text"
                                className="loan-amount-input"
                                value={formattedLoanAmount}
                                readOnly
                            />
                        </label>
                        <div className="slider-container">
                            <input
                                type="range"
                                className="form-range"
                                min={loanLimitInVNDFrom}
                                max={loanLimitInVND}
                                step="1000000"
                                id="customRange1"
                                style={{ background: LoanAmountBackground }}
                                value={loanAmount}
                                onChange={handleSliderChange}
                            />
                            <p className="slider-label">{formatNumberWithCommas(loanLimitInVNDFrom)}</p>
                            <p className="slider-label">{formatNumberWithCommas(loanLimitInVND)}</p>
                        </div>
                    </div>
                    <div className="loan-term">
                        <label htmlFor="customRange2" className="form-label">
                            <span>Kỳ hạn vay</span>
                            <input
                                type="text"
                                className="loan-term-input"
                                value={loanTerm}
                                readOnly
                            />
                        </label>
                        <div className="slider-container">
                            <input
                                type="range"
                                className="form-range"
                                min={loanItem.fromTerm}
                                max={loanItem.term}
                                id="customRange2"
                                style={{ background: LoanTermBackground }}
                                value={loanTerm}
                                onChange={handleTermChange}
                            />
                            <p className="slider-label">{loanItem?.fromTerm} tháng</p>
                            <p className="slider-label">{loanItem.term} tháng</p>
                        </div>
                    </div>
                    <div className="terms-container">
                        <p>Để đăng ký và sử dụng dịch vụ từ Gocard, xin vui lòng đọc và đồng ý với
                            <a href="/dieu-khoan-su-dung">Điều khoản sử dụng dịch vụ</a>
                            của chúng tôi.
                        </p>
                        <div className="terms-options">
                            <div className="form-check">
                                <div>
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="flexRadioDefault"
                                        id="flexRadioDefault1"
                                        value="agree"
                                        onChange={handleAgreementChange}
                                        checked={agreement === 'agree'}
                                    />
                                </div>
                                <label className="form-check-label" htmlFor="flexRadioDefault1">
                                    Tôi đồng ý và muốn sử dụng dịch vụ.
                                </label>
                            </div>
                            <div className="form-check">
                                <div>
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="flexRadioDefault"
                                        id="flexRadioDefault2"
                                        value="disagree"
                                        onChange={handleAgreementChange}
                                        checked={agreement === 'disagree'}
                                        style={{ alignSelf: 'flex-start', marginTop: '0.8rem' }}
                                    />
                                </div>
                                <label className="form-check-label" htmlFor="flexRadioDefault2" style={{ lineHeight: '14px' }}>
                                    Tôi không đồng ý với toàn bộ hoặc một phần<br /> trong Điều khoản dịch vụ.
                                </label>
                            </div>
                        </div>
                    </div>
                    <Turnstile
                        className="varify"
                        sitekey="0x4AAAAAAAgoq9QLELbUz05a"
                        onVerify={(token: string) => verifyCaptcha(token)}
                    />
                    <button
                        className="loan-submit-button"
                        disabled={checkCaptcha}
                        onClick={() => handleSearch("dang-ky-khoan-vay", 'loan')}
                    >
                        Đăng ký khoản vay
                    </button>
                </div>
                <ScrollToTopButton/>
            </Spin>
        </>
    );
}

export default React.memo(RegisterLoanPageView);
